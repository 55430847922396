import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentList from '../components/content-list/content-list';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';
import WYSIWYG from '../components/wysiwyg/wysiwyg';

const PlacesPage = ({ data }) => {
  return (
    <DefaultLayout data={data}>
      <SEO
        title="Places at City Springs"
        description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
        siteConfig={data.site.siteMetadata}
        pageUrl="/places"
      />

      <ContentList>
        <WYSIWYG>
          <h1 className="heading-2">Restaurants at City Springs</h1>
          <div className="multiColText">
            <p>
              The mission of the Sandy Springs Performing Arts Center is to
              enhance the quality of life of the Sandy Springs community by
              providing diverse arts, entertainment, educational, business, and
              civic opportunities.
            </p>
            <p>
              When the City of Sandy Springs incorporated in 2005, the city
              lacked a center point and a traditional component of most cities –
              a town square. That challenge presented the community with the
              unique opportunity to craft its downtown from scratch,
              transforming a centrally-located strip mall with a huge asphalt
              parking lot into a 21st Century town square.
            </p>
            <p>
              Planning began in 2012, with a public-involved planning process to
              develop a master plan for the City’s downtown area. The community
              asked for the creation of a unique, vibrant, walkable City Center,
              which incorporates mixed-use development, introducing new dining,
              high-end residential living, and amenity retail and entertainment
              options. The community also sought an appropriate setting for a
              performing arts facility. In determining the content desired
              related to the arts which would influence ultimate design, the
              community was again engaged in public visioning sessions, also
              including members of the arts community.
            </p>
            <p>
              The resulting design created a complex which features a main
              theatre designed to support a wide-range of programs and
              activities. Sizeable at 1,070 seats, the Byers Theatre also
              incorporates a sense of intimacy ideal for theatre productions.
              Versatility is the best adjective to describe the Studio Theatre
              which provides the right sized venue for smaller productions, in
              addition to its flexibility to meet the needs of corporate
              meetings and events.
            </p>
            <p>
              Located above the Studio Theatre is an open-air CityView Terrace,
              offering compelling views of City Springs and beyond. This
              rentable space is equally fitting as an intimate performance space
              or reception. The Terrace is adjacent to the Conference Center
              which offers a variety of spaces for company meetings and special
              events.
            </p>
            <p>
              The City Green features a four-acre park, which can be used for
              gatherings, festivals and outdoor concerts. Shaded areas are
              designed for quiet reflection, a game of chess, or simply people
              watching. The Green also serves as an outdoor gallery space,
              featuring sculptures as part of an annual public art competition.
            </p>
          </div>
        </WYSIWYG>
      </ContentList>
      <ContentList data={data.allNodePlace.edges} color="blue">
        <WYSIWYG>
          <h2 className="heading-2">Restaurants Nearby</h2>
        </WYSIWYG>
      </ContentList>
    </DefaultLayout>
  );
};

PlacesPage.propTypes = {
  data: PropTypes.object
};

export default PlacesPage;

export const query = graphql`
  query PlacesPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allNodePlace {
      edges {
        node {
          id
          title
          body {
            summary
          }
          path {
            alias
          }
          field_image {
            alt
          }
          relationships {
            node_type {
              name: drupal_internal__type
            }
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width: 331, height: 186) {
                    ...GatsbyImageSharpResolutions
                  }
                  sizes(maxWidth: 331, maxHeight: 186) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
    }
    allTaxonomyTermPlaceTags {
      edges {
        node {
          name
          drupal_internal__tid
          drupal_id
        }
      }
    }
  }
`;
